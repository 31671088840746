import './contact.css'
import contacts from './data'

const Contact = () => {
  return (
    <section id="contact" data-aos="fade-up">
      <h2>Get In Touch</h2>
      <p>Feel free to send me a message via email at any time!</p>
      <div className="container contact__container" data-aos = "fade-up">
      {
        contacts.map(contact => <a key={contact.id} href={contact.link} target="_blank" 
        rel = "noopener noreferrer">{contact.icon}
        </a>)
      }
      </div>

        </section>
  )
}

export default Contact