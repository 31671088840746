import Image1 from '../../assets/project1.png'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.png'
import Image4 from '../../assets/project4.png'
import Image5 from '../../assets/project5.png'
import { FaJava } from "react-icons/fa";
import { SiMysql } from "react-icons/si";
import { SiAndroidstudio } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { IoLogoCss3 } from "react-icons/io";
import { GrGraphQl } from "react-icons/gr";
import { SiDrupal } from "react-icons/si";
import { GrGatsbyjs } from "react-icons/gr";
import { FaFigma } from "react-icons/fa";
import restAPIImage from "../../assets/rest-api-icon.png"
import pop3Image from "../../assets/pop3-icon.png"
import jiraImage from "../../assets/logo-jira.png"
import { SiJira } from "react-icons/si";
import { TbBrandMysql } from "react-icons/tb";
import cImage from "../../assets/c.png"

const data = [
    {
        id: 1,
        category: 'Full-stack', 
        image: Image1,
        title: "Airline Management System",
        desc: "The system equips users with comprehensive tools to manage their flights effectively. It enables them to do much more than just book tickets; they can also monitor flight arrivals and departures, assign pilots to flights, manage crew rotations, and receive detailed route summaries for specific journeys. For route summaries, the system offers extensive information, including the number of legs, leg sequence, total route distance, the number of flights required for each route, and the sequence of airports. It also incorporates a dynamic Graphical User Interface (GUI), ensuring an engaging user interaction which significantly improves usability and aesthetic appeal.",
        //demo: 'https://github.com/ykim3001/airline-manager/blob/main/screen_mockups_example.pdf',
        github: 'https://github.com/ykim3001/airline-manager',
        languages: [
            { name: 'Java', logo: <FaJava />, type:'icon' },
            { name: 'Mysql', logo: <SiMysql />, type:'icon' },
        ]
    },
    {
        id: 2,
        category: 'Full-stack',
        image: Image2,
        title: "Cross the Road",
        desc: "This full-stack Android app game draws inspiration from the iconic 'Frogger', a classic arcade game from the 1980s. While it retains all the cherished features of the original Frogger, it also introduces additional functions to enhance the user's enjoyment. The game encompasses a range of functionalities, from seamless database integration to contemporary user interface design, offering a modern twist on a beloved classic.",
        //demo: 'http://egatortutorials.com',
        github: 'https://github.com/ykim3001/cross-the-road',
        languages: [
            { name: 'Java', logo: <FaJava />,type:'icon' },
            { name: 'AndroidStudio', logo: <SiAndroidstudio />,type:'icon' },
        ]
    },
    {
        id: 3,
        category: 'Full-stack',
        image: Image3,
        title: "Empathy Bytes",
        desc: "As technology advances, there's a growing concern that we might lose our innate sense of empathy. The mission of this project is to combine empathy with the fabric of every technological creation we produce. To gather insights, we've interviewed a diverse group of individuals on campus, from professors to librarians and even café workers in Georgia Tech. I’ve crafted dynamic and interactive web components, enabling users to explore interviews, photographs, and various multimedia elements on the website. Visit this website to experience empathy in digestible 'bytes'!",
        demo: 'https://educast.library.gatech.edu/',
        github: 'https://github.com/EmpathyBytes/empathy-bytes-2023',
        languages: [
            { name: 'React', logo: <FaReact />, type:'icon' },
            { name: 'Javascript', logo: <IoLogoJavascript/> ,type:'icon'},
            { name: 'Graphql', logo : <GrGraphQl /> ,type:'icon'},
            { name: 'html5', logo : <FaHtml5 /> ,type:'icon'},
            { name: 'css', logo : <IoLogoCss3 />,type:'icon'},
            { name: 'Gatsbyjs', logo : <GrGatsbyjs />,type:'icon'},
            { name: 'Drupal', logo : <SiDrupal />,type:'icon'},
            { name: 'Figma', logo : <FaFigma />,type:'icon'},
        ]
    },
    {
        id: 4,
        category: 'Backend',
        image: Image4,
        title: "VOC tracker",
        desc: "This system streamlines the process by automating the upload of VOC (Voice of the Customer) emails into Atlassian Jira as 'issues' to effectively track VOC processes. It also automatically assigns the appropriate team member to address the VOC, categorizes the issue, sets a deadline, labels the sender along with their email, and tags the status—such as 'in progress' or 'done'—to monitor the progress efficiently. Furthermore, the system can link related VOC emails to the original 'parent' VOC as comments of the original issue. To implement this, I designed a parent-child linking mechanism storing each message ID as a unique key utilizing database for accurate tracking and data storage. Please understand that a demo is not possible due to the company's security policies.",
        //demo: 'http://egatortutorials.com',
        //github: 'https://github.com/egattor'
        languages: [
            { name: 'Java', logo: <FaJava />,type:'icon' },
            { name: 'RestAPI', logo: restAPIImage ,type:'image'},
            { name: 'Mysql', logo: <SiMysql />, type:'icon' },
            // { name: 'POP3', logo : pop3Image ,type:'image'},
            { name: 'Jira', logo : <SiJira />,type:'icon'},
        ]
    },
    {
        id: 5,
        //category: 'Other',
        image: Image5,
        title: "Flappy Bird GBA",
        desc: "This Game Boy Advance game is inspired by the iconic 'Flappy Bird', a classic of the arcade game genre. It employs Direct Memory Access (DMA) to execute desired functionalities seamlessly, ensuring a smooth, tear-free display within the GBA environment.",
        //demo: 'http://egatortutorials.com',
        github: 'https://github.com/ykim3001/flappy-bird-gba',
        languages: [
            { name: 'C', logo: cImage,type:'image' },
        ]
    },
]


export default data