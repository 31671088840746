import {FaAward} from 'react-icons/fa'
import {TbBooks} from 'react-icons/tb'
import {BiHappyHeartEyes} from 'react-icons/bi'
import { IoSchoolSharp } from "react-icons/io5";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

const data = [
    {id: 1, icon: <IoSchoolSharp/>, title: 'Education', desc: 'B.S. in Georgia Institute of Technology'},
    {id: 2, icon: <FaAward/>, title: 'Experience', desc: 'Interned at Samsung as a Software Developer'},
    {id: 3, icon: <AiOutlineFundProjectionScreen />, title: 'Projects', desc: 'Various languages & skills'}
    ]



    export default data;