import HeaderImage from '../../assets/Subject.png'
import data from './data'
import './header.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

const Header = () => {
  useEffect(()=> {
    AOS.init({duration: 2000})
  }, [])
  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos = "fade-in">
          <img src={HeaderImage} alt="Header Portait" />
        </div>
        <h3 data-aos = "fade-up">Yunsu Kim</h3>
        <p data-aos = "fade-up">
          Welcome! <br />
        I'm excited to share this space with you. It's an opportunity for you to delve deeper into my experiences, projects, and the unique perspectives I bring to these fields. I hope you find my site both informative and inspiring as you get to know more about me.
        </p>
        <div className="header__cta" >
          <a href="#contact" className='btn primary'>Let's Talk</a>
          <a href="#portfolio" className='btn light'>My Work</a>
        </div>
        <div className="header__socials">
          {
            data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header