const data = [
    {
      id: 1,
      question: "What's your MBTI?",
      answer: "I alternately get INTP and ENTP. I think of myself as more of an introvert, but there are times when I act like an extrovert. It's probably a 50:50 mix."
    },
    {
      id: 4,
      question: "Where's your favorite study spot?",
      answer: "PG 3rd. (3rd floor of the Price Gilbert Library at Georgia Tech)"
    },
    {
      id: 2,
      question: "What's your hobbies?",
      answer: "Hanging out with my friends, working out, eating good foods are some of my favorite activities. And I'm always trying to experience new things...to expand my insights!"
    },
    {
      id: 5,
      question: "What are you passionate about?",
      answer: "I am passionate about working on any project that can be created or improved through my coding expertise."
    },
    {
      id: 3,
      question: "Do you collect anything?",
      answer: "Precious memories. Those are going to be the fuel for my life and future."
    },
    // {
    //   id: 5,
    //   question: "What makes you unique?",
    //   answer: "What distinguishes me is my commitment to honesty and trust. I firmly believe that these values are the cornerstone of all successful personal and professional relationships."
    // },
  ]

  export default data