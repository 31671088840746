import './about.css'
import AboutImage from '../../assets/about.jpg'
import CV from '../../assets/Yunsu_Kim_Resume.pdf'
import {HiDownload} from 'react-icons/hi'
import Card from '../../components/Card'
import data from './data'

const About = () => {
  return (
    <section id="about" data-aos="fade-up">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {
              data.map(item => (
                <Card key={item.id} className="about__card">
                  <span className='about__card-icon'>{item.icon}
                  </span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }
          </div>
          <p>
        My name is Yunsu Kim, and I'm currently a fourth-year Computer Science student at Georgia Institute of Technology specializing in Information Internetworks & Media. 
        I have serious passion for full-stack development and data science and I've always been deeply passionate about constructing projects in my field of interest.
          </p>
          <p>
          You can find my CV attached here.
          </p>
          <a href={CV} download className='btn primary'>Download CV<HiDownload/></a>
        </div>
      </div>
    </section>
  )
}

export default About