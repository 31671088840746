import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Home'},
    {id: 2, link: '#about', title: 'About'},
    {id: 3, link: '#portfolio', title: 'Portfolio'},
    {id: 4, link: '#faqs', title: 'FAQ'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/yun-su-kim/', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://github.com/ykim3001', icon: <AiFillGithub/>},
    {id: 3, link: 'https://www.instagram.com/okjkr23/', icon: <AiOutlineInstagram/>}
]