import {AiOutlineInstagram} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {AiOutlineDribbble} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'

const data = [
    
    {id: 1, link: 'https://www.linkedin.com/in/yun-su-kim/', icon: <AiOutlineLinkedin/>},
    // {id: 3, link: 'https://dribbble.com', icon: <AiOutlineDribbble/>},
    {id: 2, link: 'https://github.com/ykim3001', icon: <AiFillGithub/>},
    {id: 3, link: 'https://www.instagram.com/okjkr23/', icon: <AiOutlineInstagram/>}
]

export default data