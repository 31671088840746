import React from 'react';
import Card from '../../components/Card';
import data from './data';

const Project = ({ project }) => {
  return (
    <Card className="portfolio__project">
        <div className="portfolio__project-image">
            <img src={project.image} alt="Portfolio Project" />
        </div>
        <h4>{project.title}</h4>
        
        {/* Language logos section */}
        <div className="portfolio__project-logos">
            {project.languages && project.languages.map((language) => (
                <span key={language.name} className="portfolio__language-logo" title={language.name}>
                {language.type === 'icon' ? (
                    language.logo
                ) : (
                    <img src={language.logo} alt={`${language.name} logo`} />
                )}
            </span>
            ))}
        </div>

        <p>{project.desc}</p>
        <div className="portfolio__project-cta">
            {project.demo && (
                <a href={project.demo} className="btn sm" target="_blank" rel="noopener noreferrer">Live</a>
            )}
            {project.github && (
                <a href={project.github} className="btn sm primary" target="_blank" rel="noopener noreferrer">Github</a>
            )}
        </div>
    </Card>
  );
}

export default Project;

