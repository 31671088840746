import faqs from './data'
import FAQ from './FAQ'
import './faqs.css'
import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const FAQs = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  })
  return (
    <section id="faqs">
      <h2>Frequently Asked Questions</h2>
      <p>
      Want to know about me? Here are some questions you might have. <br />Click to reveal the answers. If you have any additional questions, feel free to send me a message through the contact section!
      </p>
      <div className="container faqs__container" data-aos = "fade-in">
        {
          faqs.map(faq => (
            <FAQ key={faq.id} faq={faq}/>
          ))
        }
      </div>
    </section>
  )
}

export default FAQs